<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          AgentScout
        </h2>
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Benvenuto 👋
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!--
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Recupera password?</small>
                  </b-link>
                  -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!--
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>
              -->

              <b-alert show variant="danger" v-if="erroreLoginServer"><div class="alert-body font-small-3">{{ erroreLoginServerTesto }}</div></b-alert>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Accedi
              </b-button>
            </b-form>
          </validation-observer>

          <div class="divider my-2">
            <div class="divider-text">
              o
            </div>
          </div>

          <p class="text-center">se non ricordi i tuoi dati di login</p>
          <b-button block variant="outline-primary" :to="{name:'auth-linklogin'}">accesso tramite email</b-button>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import router from '@/router'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      /*
      userEmail: 'admin@admin.com', //dati di accesso pre-inseriti per semplificare i test
      password: '12345678',
      */
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      campilogin: {},
      erroreLoginServer: false,
      erroreLoginServerTesto: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created(){
    console.log(router.history.current.query.status)
    if((router.history.current.query.status !== undefined) && (router.history.current.query.status == 'again')){
      this.erroreLoginServer = true;
      this.erroreLoginServerTesto = 'Sessione scaduta - prego effettuare login';
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {

          this.$http.post('api/auth/login', {
            email: this.userEmail, 
            password: this.password 
            }).then(response => { 
                /*
                console.log("risposta server -------- status code -> "+response.data.statusCode)
                console.dir(response.data)
                */
               
                /*
                console.log("--------")
                console.log(response.data.reply)
                */
                
                if(response.data.statusCode===200){
                //risposta positiva
                  /*
                  console.log("--------")
                  console.dir(response.data.reply.userData)
                  console.log("--------")
                  */

                  /*
                  console.log("access_token -> "+response.data.reply.access_token)
                  console.log("setRefreshToken -> "+response.data.reply.access_token)
                  console.log("ruolo utente loggato -> "+response.data.reply.userData.role)
                  */

                  useJwt.setToken(response.data.reply.access_token)
                  useJwt.setRefreshToken(response.data.reply.access_token)
                  localStorage.setItem('userData', JSON.stringify(response.data.reply.userData))
                  
                  //gestione permessi dell'utente - per la persistenza
                  this.$ability.update(response.data.reply.userData.ability)
                  
                  this.$router.replace(getHomeRouteForLoggedInUser(response.data.reply.userData.role))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Ciao ${response.data.reply.userData.name || response.data.reply.userData.username}`,
                          icon: 'UserCheckIcon',
                          variant: 'success',
                          text: `sei loggato come ${response.data.reply.userData.role}`,
                        },
                      })
                    })
                    
                } else {
                  //risposta negativa (errore sul server)
                  console.log("server ha risposto ... ma con LOGIN SBAGLIATA")
                  if(response.data.reply.message == 'Unauthorized'){
                    this.erroreLoginServerTesto = 'Accesso Negato - username e password non sono corrette';
                  } else {
                    this.erroreLoginServerTesto = response.data.reply.message;
                  }
                  this.erroreLoginServer = true;
                }

            }).catch(e => {
                console.log(e);
                this.$refs.loginForm.setErrors(e.response.data.error)
            });
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
